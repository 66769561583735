
<template>
  <!-- 会员权益体系 -->
  <div>
    <div class="heading">
      <h3>会员营销管理</h3>
      <p>
        连接所有的会员触点和平台，构建一体化会员系统，实现跨平台的会员私域流量运营模式。
      </p>
      <button @click="onapply()">申请试用</button>
    </div>
    <div class="activity">
      <h5>全渠道会员统一管理</h5>
      <p class="title">
        企业拥有多个渠道的会员数据已经成为常态，为了避免营销活动对会员的重复触达，实现统一的会员营销策略，有必要对分散在全渠道的会员数据进行统一管理。
      </p>
      <img src="../../assets/images/member.png">
    </div>
    <div class="content">
      <div>
        <h6>构建完善的会员中台体系</h6>
        <p>
          ·会员数据管理平台: 建立会员CDP数据平台，对数据进行清洗、整合、分析和洞察<br />
          ·会员标签体系: 建立体系化的标签体系，提供完善的会员标签解决方案和顾问服务<br />
          ·会员圈群分层: 智能化会员分层分组，并可支持跨平台和跨渠道的会员圈群运营<br />
          ·精准会员画像: 通过标签、来源、事件、活跃度等指标形成精准360°会员画像<br />
          ·会员旅程设计: 基于会员的标签和分层，分别设置不同的会员生命周期旅程策略<br />
          ·会员自动化营销: Always-on自动化营销管理，可实现超大规模的个性化会员营销
        </p>
        <button @click="onapply()">免费试用</button>
      </div>
      <img src="../../assets/images/member01.png" />
    </div>
    <div class="system">
      <h6>丰富的会员营销场景</h6>
      <div>
        <p>会员内容推送</p>
        <p>会员忠诚度管理</p>
        <p>新会员促进首购</p>
        <p>会员积分和权益</p>
        <p>会员等级管理</p>
        <p>营销卡券管理</p>
        <p>异常会员订单分析</p>
        <p>会员拉新留存促活</p>
      </div>
    </div>
    <div class="foot">
      <h6>500＋企业的共同选择</h6>
      <p>千人千面的策略布置</p>
      <img src="../../assets/images/hzqy.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["childEvent"],
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    onapply(){
        // this.childEvent(true);
        window.location.href = "http://xdnmxba7z7d6llfo.mikecrm.com/uMx7QGf";
      }
  },
};
</script>

<style scoped lang="less">
.heading {
  height: 6.6rem;
  background-image: url(../../assets/images/digitization05.png);
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  h3 {
    font-size: 0.56rem;
    padding-top: 2.05rem;
  }
  p {
    text-align: left;
    width: 7.43rem;
    margin: 0.18rem auto;
    font-size: 0.2rem;
    line-height: 0.3rem;
  }
  button {
    width: 1.48rem;
    height: 0.64rem;
    background-color: #ffc53d;
    border: 0;
    color: #fff;
    font-size: 0.2rem;
    margin-top: 0.4rem;
    border-radius: 0.05rem;
  }
}
.activity {
  padding: 1.36rem 2.4rem 1rem;
  h5 {
    font-size: 0.28rem;
    text-align: center;
    padding-bottom: 0.48rem;
  }
  .title {
    font-size: 0.18rem;
    padding-bottom: 0.26rem;
    padding-left: 0.3rem;
    color: #666;
  }
  img{
    width: 14.68rem;
    height: 2.48rem;
  }
}

.content {
  display: flex;
  justify-content: center;
  padding: 0.68rem 0;
  background-color: #fffff5;
  img {
    width: 7.08rem;
    height: 4.38rem;
  }
  div {
    width: 6.52rem;
    padding-left: 0.88rem;
    padding-top: 0.38rem;
    h6 {
      font-size: 0.28rem;
      line-height: 0.42rem;
      color: #333;
      padding-bottom: 0.32rem;
    }
    p {
      font-size: 0.18rem;
      color: #666;
      padding-bottom: 0.12rem;
      line-height: 0.27rem;
    }
    button {
      width: 1.48rem;
      height: 0.64rem;
      background-color: #333;
      color: #ffc53d;
      border-radius: 0.05rem;
    }
  }
}
.system {
  padding: 1rem 2.2rem 1rem;
  h6 {
    font-size: 0.28rem;
    text-align: center;
  }
  div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 0.58rem;
    p {
      font-size: 0.22rem;
      font-weight: 600;
      line-height: 0.94rem;
      width: 3.3rem;
      height: 0.94rem;
      box-sizing: border-box;
      padding-left: 0.6rem;
      background-image: url(../../assets/images/system_bj.png);
      background-size: 100% 100%;
      box-shadow: 10px 10px 30px 1px rgba(0, 0, 115, 0.13);
      margin: 0 0.4rem 0.45rem 0;
      border-radius: 0.1rem;
    }
  }
}
.foot {
  h6 {
    font-size: 0.28rem;
    text-align: center;
    padding-bottom: 0.2rem;
  }
  p {
    font-size: 0.18rem;
    color: #666;
    text-align: center;
  }
  img {
    width: 19.2rem;
    height: 7.88rem;
  }
}
</style>
